
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
























































































































































































.number {
  font-size: calc(0.2vw + 1.8rem);
}

.boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 35rem));
  grid-gap: 3.5rem;
}

.box__amount {
  line-height: 1.2;
}

.steps {
  background: #f3f3f3;
  counter-reset: step;
}

.steps--title {
  font-family: Swiss721BT, sans-serif;
  padding: 2em 0;
  margin: 0;
  font-weight: 600;
}

.steps--items {
  padding: 24px 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.steps--items:before {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 20px;
  left: 0;
  background: #c6c6c6;
}

.steps--item {
  position: relative;
  padding: 24px 0;
  flex: 1 1 0;
}

.steps--item.active:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: white;
  border: 3px solid hsla(355, 100%, 50%, 1);
  border-radius: 100px;
  position: absolute;
  top: -13px;
  left: 0;
}

.steps--item.checked:before {
  content: '';
  display: block;
  width: 105%;
  height: 4px;
  position: absolute;
  top: -4px;
  left: 0;
  background: hsla(104, 86%, 38%, 1);
}

.steps--item.checked:first-child:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: center url('../../../assets/images/check.svg') no-repeat;
  background-size: contain;
  position: absolute;
  top: -14px;
}

.steps--item__title {
  font-weight: 600;
  margin: 0;
  font-size: 1.5rem;
}

.steps--item__info {
  margin: 0;
  font-size: 1.4rem;
  color: hsla(0, 0%, 63%, 1);
}

.steps--item__title:before {
  counter-increment: step;
  content: counter(step) '. ';
}

.steps--item.checked .steps--item__title {
  color: hsla(104, 86%, 38%, 1);
}

.steps--item.active .steps--item__title {
  color: hsla(355, 100%, 50%, 1);
}

.steps--item.active .steps--item__info {
  color: hsla(0, 0%, 20%, 1);
}

@media screen and (max-width: 960px) {
  .steps--item {
    visibility: hidden;
    width: 0;
    height: 0;
    flex: none;
    padding: 24px 3px;
  }

  .steps--item.active,
  .steps--item.active + .steps--item {
    visibility: visible;
    width: 48%;
    height: auto;
  }
}

/***********************************************/
.steps-small {
  width: 100%;
  min-height: 400px;
  display: inline-block;
  vertical-align: top;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 1em 1.5em;
  margin: 0 1em 1em 0;
  max-width: 450px;
}

.steps-small.in-progress {
  background: top 20px right 20px url('../../../assets/images/wrench.svg') no-repeat,
  white;
  background-size: 30px 30px;
}

.steps-small.finished {
  background: top 20px right 20px url('../../../assets/images/check.svg') no-repeat,
  white;
  background-size: 30px 30px;
}

.steps-small--header {
  padding: 2em 0 0 40%;
  height: 7em;
  background: left center url('../../../assets/images/illu-raccordement.svg') no-repeat;
  background-size: 35%;
}

.steps-small--title {
  margin: 0;
}

.steps-small--status {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ff4b00;
  margin: 0.5em 0;
}

.finished .steps-small--status {
  color: hsla(104, 86%, 38%, 1);
}

.steps-small--items {
  position: relative;
  margin: 3em 0 1em;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.steps-small--items:before {
  content: '';
  position: absolute;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  top: 6px;
}

.steps-small--item {
  position: relative;
  z-index: 2;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background: rgb(100, 100, 100);
}

.in-progress .steps-small--item.checked {
  background: center url('../../../assets/images/check-red.svg') no-repeat;
  background-size: contain;
}

.finished .steps-small--item.checked {
  background: center url('../../../assets/images/check.svg') no-repeat;
  background-size: contain;
}

.steps-small--infos {
  font-size: 1.4rem;
}

.icon-elec {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: center url('../../../assets/images/flash.svg') no-repeat;
  background-size: contain;
}
